@import 'ui/Colors';

.wrapper {
  padding: 12px 10px 3px 15px;
  border-bottom: solid 1px $border;
  background: #ffffff;

  &.notSeen {
    background: #f5fbff;
  }
}

.body {
  display: flex;
  justify-content: space-between;

  .text {
    flex: 4;

    .title {
      font-size: 12px;
      margin-bottom: 8px;
      text-transform: uppercase;
      font-weight: $fontBold;
    }

    .content {
      font-size: 14px;
      font-weight: $fontLight;
    }

    .error {
      font-size: 14px;
      color: $attention;
    }
  }

  .link {
    color: $link;
    font-weight: $fontNormal;
    cursor: pointer;
  }

  .actions {
    flex: 1;
    padding-top: 17px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > {
      :not(:last-child) {
        margin-right: 7px;
      }
    }
  }
}

.footer {
  margin-top: 13px;
  color: $dark;
  font-weight: $fontMedium;
  font-size: 11px;
}
