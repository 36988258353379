@import 'ui/Colors';

.wrapper {
  padding: 20px 20px 0 20px;
  background: #f5f5f5;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.18);
}

.content {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
}

.left {
  flex: 1;

  .name {
    display: none;
  }

  .diagnose {
    display: none;
  }

  .assignedTo {
    display: flex;
    flex-wrap: wrap;

    .user {
      margin-bottom: 5px;
      padding: 5px 7px 5px 7px;
      border-radius: 8px;
      font-size: 9px;
      font-weight: $fontMedium;
      color: #ffffff;
      text-transform: uppercase;

      &:not(:last-child) {
        margin-right: 8px;
      }

      &.archived {
        background: $light !important;
      }
    }
  }
}

.right {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.businessGroup {
  padding-bottom: 10px;
}

.meta {
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  margin-top: 12px;

  &.addMeta {
    user-select: none;
    cursor: pointer;

    &:hover {
      color: $link;

      transition: 0.25s ease;
    }
  }

  &.wrapMeta {
    white-space: pre-line;
  }

  span {
    margin-top: -1px;
    padding-left: 6px;
  }
}

@media (min-width: $mobileLimit) {
  .content {
    flex: 1;
    display: flex;
    padding: 0;
  }

  .left {
    .name {
      display: flex;
      align-items: center;

      span {
        font-weight: $fontMedium;
        font-size: 24px;
        padding-right: 10px;
      }
    }

    .diagnose {
      display: block;
      margin-top: 1px;
      font-size: 14px;
    }

    .assignedTo {
      margin-top: 12px;
    }
  }

  .right {
    align-items: flex-end;
    margin-left: 15px;

    .meta {
      flex-direction: row-reverse;
      margin-top: 0;

      &:not(:first-child) {
        margin-top: 12px;
      }

      span {
        padding-left: 0;
        padding-right: 6px;
        text-align: right;
      }
    }
  }
}
