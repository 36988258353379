@import 'ui/Colors';

.statsRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 35px;
  font-size: 17px;

  .rowHead {
    display: flex;
    align-items: center;

    .box {
      min-width: 16px;
      width: 16px;
      height: 16px;
      border-radius: 4px;
      margin-right: 6px;
    }
  }

  &:not(:last-child) {
    border-bottom: solid 1px $border;
  }
}
