@import 'ui/Colors';

.wrapper {
  background: #ffffff;

  &:not(:last-child) {
    border-bottom: solid 1px $border;
  }
}

.user {
  font-size: 15px;
}

.date {
  font-size: 12px;
  color: $light;
  margin-bottom: 12px;
}

.title {
  font-size: 24px;
  font-weight: $fontMedium;
  margin-bottom: 15px;
}

.content {
  white-space: pre-line;
  font-size: 15px;
  font-weight: $fontLight;
}

@media (min-width: $mobileLimit) {
  .wrapper {
    border-radius: 2px;

    &:not(:last-child) {
      margin-bottom: 15px;
      border-bottom: none;
    }
  }
}
