@import 'ui/Colors';

.toolbarInner {
  flex: 1;

  .top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  .dateFilter {
    display: flex;
  }
}

.appointmentForm {
  margin-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: $mobileLimit) {
  .appointmentForm {
    padding: 0;
  }
}

@media (min-width: 1000px) {
  .appointmentForm {
    margin-bottom: 60px;
  }
}
