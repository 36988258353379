@import 'ui/Colors';

.container {
  display: flex;
  flex-direction: column;
  position: relative;
}

@media (min-width: $mobileLimit) {
  .container {
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 45px;
  }
}

@media (min-width: 1240px) {
  .container {
    margin: auto;
    width: 1240px;
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (min-width: 1310px) {
  .container {
    padding-left: 0;
    padding-right: 0;
    width: 1240px;
  }
}
