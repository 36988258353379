@import 'ui/Colors';

.wrapper {
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding-left: 20px;
  padding-right: 5px; // 15px is from Icon with expand property
  border-bottom: solid 1px $border;

  &.animate {
    animation: touchable 0.15s forwards;
  }
}

.leftWrapper {
  flex: 1;
  min-width: 0;
  display: flex;
  align-items: center;

  .leftContent {
    display: flex;
    align-items: center;
    margin-right: 12px;
  }

  .central {
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;

    .extra {
      font-size: 13px;
      font-weight: $fontMedium;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .title {
      font-size: 17px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.rightWrapper {
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 15px;
  font-weight: $fontMedium;

  .extra {
    color: $light;
    text-align: right;
    font-size: 13px;
    font-weight: $fontMedium;
  }

  &.morePadding {
    padding-right: 15px; // +5 from wrapper
  }
}

@keyframes touchable {
  0% {
    background: #ffffff;
  }

  65% {
    background: $background;
  }

  100% {
    background: #ffffff;
  }
}
