@import 'ui/Colors';

.wrapper {
  padding-top: 15px;
  padding-bottom: 25px;
  padding-left: 20px;
  padding-right: 25px;
}

.period {
  display: none;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: $fontBold;
}

.report {
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 75px;
  }

  .table {
    flex: 1;

    .row {
      display: flex;
      align-items: center;
      height: 40px;
      overflow: hidden;

      .label {
        @include lo-advance-elipsis(2);
        flex: 1;
      }

      .amount {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-left: 20px;
      }
    }

    .total {
      display: flex;
      justify-content: flex-end;
      font-weight: $fontBold;
      border-top: solid 1px $border;
      padding-top: 8px;
    }
  }

  .chart {
    display: none;
  }
}

@media (min-width: $mobileLimit) {
  .period {
    display: block;
    font-size: 23px;
    font-weight: $fontBold;
    margin-bottom: 35px;
  }

  .report {
    .table {
      margin-right: 50px;
    }

    .chart {
      display: block;
      max-width: 350px;
      min-width: 350px;
    }
  }
}
