@import 'ui/Colors';

.padding {
  padding: 35px 0;
}

.loadMore {
  flex: 1;
  display: flex;
  justify-content: center;

  .link {
    user-select: none;
    padding: 5px 8px;
    color: $link;
    font-size: 13px;
    font-weight: $fontMedium;
    cursor: pointer;
    text-transform: uppercase;

    &:hover {
      border-radius: 4px;
      background: lighten($link, 35%);
    }
  }
}
