@import 'ui/Colors';

.wrapper {
  padding-top: 25px;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  display: flex;
  align-items: center;

  &.small {
    padding-bottom: 10px;
  }

  &.normal {
    padding-bottom: 25px;
  }

  &.large {
    padding-bottom: 45px;
  }
}

.image {
  margin-bottom: 25px;

  &.small {
    width: 50px;
  }

  &.normal {
    width: 100px;
  }

  &.large {
    width: 250px;
  }
}

.heading {
  text-align: center;
  font-size: 25px;
  font-weight: $fontMedium;
  margin-bottom: 10px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  .intro {
    text-align: center;
    font-size: 17px;
    font-weight: $fontLight;
    margin-bottom: 18px;
  }
}

@media (min-width: $tabletLimit) {
  .wrapper {
    &.large {
      padding-top: 75px;
    }
  }
}

@media (min-width: $mobileLimit) {
  .wrapper {
    padding-left: 0;
    padding-right: 0;

    &.large {
      padding-top: 150px;
    }
  }
}
