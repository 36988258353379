@import 'ui/Colors';

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content {
  margin-top: -10px;
  height: 100%;
}

.headline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
  border-bottom: solid 1px $border;

  span {
    font-size: 21px;
    font-weight: $fontMedium;
  }
}

.description {
  font-size: 15px;
  padding-top: 8px;
  padding-bottom: 7px;
  border-bottom: solid 1px $border;
  margin-bottom: 12px;
}

.globalError {
  color: $attention;
}

.sendButton {
  display: flex;
  height: 100%;
  padding-bottom: 2px;
  align-items: flex-end;
  justify-content: flex-end;
}
