@import 'ui/Colors';

.wrapper {
  display: flex;
  flex-direction: column;
  position: fixed;
  overflow: hidden;
  z-index: 101;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  animation: showDialog 0.3s forwards;
  transform: translateZ(0);

  &.closing {
    animation: hideDialog 0.25s forwards;
  }

  .bodyWrapper {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .children {
    padding-top: 27px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .actions {
    margin-top: 37px;
    margin-bottom: 27px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.titleWrapper {
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  height: 55px;
  font-size: 19px;
  font-weight: $fontMedium;
  box-shadow: 0 2px 2px rgba(118, 127, 149, 0.15);

  .title {
    max-width: 415px;
  }
}

.error {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 15px;
  font-weight: $fontMedium;
  background: $attention;
  width: 100%;
  margin-bottom: 27px;
  margin-top: 27px;
  padding: 5px 10px;
}

@keyframes showDialog {
  0% {
    right: -40px;
    opacity: 0.8;
  }

  100% {
    right: 0;
    opacity: 1;
  }
}

@keyframes hideDialog {
  0% {
    right: 0;
    opacity: 0.8;
  }

  100% {
    right: -40px;
    opacity: 0;
  }
}

@media (min-width: $mobileLimit) {
  .wrapper {
    border-left: solid 1px $border;

    &.normal {
      width: 450px;
    }

    &.extended {
      width: 750px;
    }
  }
}
