@import 'ui/Colors';

@mixin common-padding {
  padding-left: 20px;
  padding-right: 20px;
}

.goBack {
  user-select: none;
  margin-bottom: 7px;

  .clickable {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }

  span {
    padding-left: 6px;
    color: $link;
    font-size: 15px;
  }
}

.form {
  padding-left: 20px;
  padding-right: 20px;
}

.wrapper {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
}

.dropdown {
  @include common-padding;
  position: absolute;
  top: 9px;
  right: 0;
}

.cancelled {
  @include common-padding;
  margin-top: -5px;
  margin-bottom: 20px;

  .cancelledBy {
    font-size: 11px;
    color: $attention;
    font-weight: $fontBold;
    text-transform: uppercase;
  }

  .reason {
    font-size: 16px;
  }
}

.title {
  @include common-padding;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1px;

  span {
    font-size: 21px;
    font-weight: $fontMedium;
  }
}

.time {
  @include common-padding;
  font-size: 15px;
}

.users {
  @include common-padding;
  margin-top: 20px;
  margin-bottom: 25px;

  .participant {
    margin-left: -3px;
    display: flex;
    align-items: center;

    span {
      @include lo-ellipsis;
      font-size: 17px;
      padding-left: 10px;
    }
  }
}

.events {
  border-top: solid 1px $border;

  .event {
    padding-top: 8px;
    padding-bottom: 3px;
    border-bottom: solid 1px $border;

    .name {
      @include common-padding;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;

      .label {
        @include lo-ellipsis;
        color: $link;
        cursor: pointer;
      }

      .birthday {
        display: flex;
        justify-content: flex-end;
        min-width: 25px;
      }
    }

    .extra {
      @include common-padding;
      @include lo-ellipsis;
      font-size: 12px;
      color: $light;
    }

    .notes {
      margin-top: 8px;
    }

    .actions {
      @include common-padding;
      margin-top: 17px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .group {
        display: flex;
        align-items: center;
      }

      .action {
        font-size: 10px;
        text-transform: uppercase;
        color: #ffffff;
        cursor: pointer;
        font-weight: $fontMedium;
        padding: 4px 6px;
        background: $primary;
        border-radius: 3px;

        &:hover {
          background: darken($primary, 5%);
        }

        &:not(:last-child) {
          margin-right: 12px;
        }
      }
    }
  }
}

@media (min-width: $mobileLimit) {
  .goBack {
    margin-left: auto;
    margin-right: auto;
    width: 555px;
  }

  .form {
    padding: 0;
  }

  .wrapper {
    margin: auto;
    width: 555px;
    min-height: 350px;
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  }
}
