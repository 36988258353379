@import 'ui/Colors';

.diagnoses {
  border-top: solid 1px $border;
}

.diagnose {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 17px;
  border-bottom: solid 1px $border;

  &:hover {
    background: $hover;

    .delete {
      display: block;
    }
  }

  .delete {
    display: none;
    margin-left: 30px;
  }
}
