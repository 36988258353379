@import 'ui/Colors';

.wrapper {
  user-select: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: $fontMedium;
  font-size: 15px;
}

.default {
  color: $link;
}

.attention {
  color: $attention;
}
