@import 'ui/Colors';

.wrapper {
  padding: 20px 0;
}

.groupTitle {
  padding-left: 20px;
}

.payment {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .service {
    display: flex;
    align-items: center;

    span {
      padding-left: 12px;
      @include lo-advance-elipsis(2);
    }
  }

  .right {
    display: flex;
    align-items: center;
    font-size: 15px;

    .createdBy {
      @include lo-ellipsis;
      width: 150px;
      margin-left: 35px;
      text-align: right;
    }

    .date {
      margin-left: 35px;
    }

    .money {
      width: 120px;
      text-align: right;
      font-weight: $fontMedium;
    }
  }
}
