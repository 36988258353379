@import 'ui/Colors';

.wrapper {
  margin-left: 40px;
  width: 200px;
}

.sidebar {
  user-select: none;
  width: 100%;
  background: #ffffff;
  border: solid 1px $border;
  border-radius: 4px;

  .action {
    display: flex;
    align-items: center;
    height: 50px;
    cursor: pointer;
    padding-left: 12px;

    &:not(:last-child) {
      border-bottom: solid 1px $border;
    }

    &:hover {
      background: $primary;

      .label {
        color: #ffffff;
      }

      i {
        color: #ffffff !important;
      }

      &.attention {
        background: $attention;
      }
    }

    &.disabled,
    &.disabled:hover {
      background: #ffffff;

      .label {
        color: $light;
      }

      i {
        color: $light !important;
      }
    }

    .label {
      display: flex;
      align-items: center;
      margin-left: 15px;
      font-size: 15px;
    }
  }
}

.colorPicker {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  margin-top: 5px;
  padding: 5px 0;
  border-radius: 4px;
}

.sideContent {
  margin-top: 25px;
}

.actionNotes {
  font-size: 15px;
  padding-left: 2px;

  .saving {
    color: $light;
    margin-top: 5px;
  }

  .error {
    color: $attention;
    margin-top: 5px;
  }
}
