@import 'ui/Colors';

.wrapper {
  border-bottom: solid 1px $border;
}

.summary {
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  padding-left: 20px;
  padding-right: 20px;

  .main {
    font-size: 17px;
  }
}

.open {
  animation: rotate 0.2s forwards;
}

.closed {
  animation: unRotate 0.2s forwards;
}

.details {
  animation: fadeIn 0.2s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate3d(0, -4px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0px, 0);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes unRotate {
  0% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
