.wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 55px;
}

@media print {
  .wrapper {
    display: none;
  }
}
