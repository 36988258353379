@import 'ui/Colors';

html {
  height: 100%;
}

body {
  height: 100%;
  overflow: hidden;
  background-color: #ffffff;
  display: block;
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
  color: $text;
  margin: 0;
  padding: 0;
}

#main-app {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

#root {
  position: relative;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar-track {
    border-left: none;
  }
}

::-webkit-scrollbar {
  width: 0;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;

  &:link,
  &:visited,
  &:hover,
  &:active {
    color: inherit;
  }
}

@media (min-width: $mobileLimit) {
  body {
    background-color: $background;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    border-left: 1px solid $border;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #999999;
    border-radius: 10px;
  }
}

@media print {
  #main-app,
  #root {
    overflow: visible !important;
  }
}
