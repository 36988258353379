@import 'ui/Colors';

@mixin input-placeholder {
  color: $light;
  font-size: 15px;
}

.wrapper {
  display: flex;
  align-items: center;
  border-bottom: solid 1px $border;
  background: #ffffff;
  height: 35px;

  .input {
    height: 100%;
    width: 100%;
    font-size: 17px;
    border: none;
    outline: none;
    padding: 0;
    border-radius: 2px;
    font-family: 'Roboto', 'sans-serif' !important;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      @include input-placeholder;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      @include input-placeholder;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      @include input-placeholder;
    }
  }

  .prefix {
    padding-right: 6px;
  }
}

:global {
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }
}

@media (min-width: $mobileLimit) {
  .wrapper {
    border: solid 1px $border;
    height: 45px;

    .input {
      padding-left: 12px;
      padding-right: 4px;
    }

    .prefix {
      padding-left: 6px;
      padding-right: 0;
    }
  }
}
