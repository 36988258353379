@import 'ui/Colors';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 17px;
}

.left {
  @include lo-ellipsis;
  min-width: 250px;
  max-width: 250px;
  margin-right: 15px;

  .date {
    font-size: 13px;
    color: $light;
  }
}

.middle {
  display: flex;
  align-items: center;
  flex: 1;

  .circle {
    min-width: 12px;
    max-width: 12px;
    height: 12px;
    background: $primary;
    border-radius: 50%;
    margin-right: 13px;
  }

  .description {
    @include lo-advance-elipsis(2);
    overflow: hidden;
    max-height: 40px;
    margin-right: 20px;
  }
}

.amount {
  font-weight: $fontMedium;
}
