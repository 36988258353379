@import 'ui/Colors';

.head {
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 35px;
  padding-left: 20px;
  padding-right: 20px;
  background: $background;
  color: $dark;
  font-weight: $fontBold;
  font-size: 15px;
}
