@import 'ui/Colors';

.wrapper {
  padding: 20px 0;
}

.groupTitle {
  padding-left: 20px;
  padding-right: 20px;
}

.debt {
  color: $attention;
}
