@import 'ui/Colors';

.mobileWrapper {
  padding: 20px;
}

.password {
  position: relative;
  flex: 1;
}

.forgotPassword {
  position: absolute;
  top: 10px;
  right: 0;
  font-size: 14px;
  color: $dark;
  cursor: pointer;
}

.action {
  margin: auto;
  margin-top: 35px;
  width: 50%;
}

@media (min-width: $mobileLimit) {
  .forgotPassword {
    top: 12px;
    right: 12px;
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .action {
    width: 100%;
  }
}
