@import 'ui/Colors';

.loadingSpinnerWrapper {
  display: flex;
  justify-content: center;

  &.normal {
    padding-top: 150px;
    padding-bottom: 45px;

    .loadingSpinner {
      width: 36px;
      height: 36px;
      border-width: 5px;
    }
  }

  &.small {
    .loadingSpinner {
      width: 28px;
      height: 28px;
      border-width: 4px;
    }
  }
}

.loadingSpinner {
  position: relative;
  z-index: 0;
  border-radius: 50%;
  border-top: solid #eaeaea;
  border-right: solid $primary;
  border-bottom: solid #eaeaea;
  border-left: solid #eaeaea;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  animation: spinnerAnimation 0.45s infinite linear;
}

@keyframes spinnerAnimation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
