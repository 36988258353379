@import 'ui/Colors';

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 25px;

  &.withBottomBorder {
    border-bottom: solid 1px $border;
  }

  &.clickable {
    cursor: pointer;
  }

  &.resizable {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &.normal {
    &:not(.resizable) {
      height: 60px;
    }

    &.resizable {
      min-height: 60px;
    }
  }

  &.small {
    &:not(.resizable) {
      height: 50px;
    }

    &.resizable {
      min-height: 50px;
    }
  }

  .more {
    display: none;
  }

  &:hover {
    .more {
      display: block;
    }
  }
}

.content {
  flex: 1;
  overflow: hidden;
}

.mobileContextMenu {
  right: -17px;
}

.contextMenu {
  padding-top: 2px;
  margin-left: 30px;
}

@media (min-width: $mobileLimit) {
  .wrapper {
    &:hover {
      background: $hover;
    }
  }
}
