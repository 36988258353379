@import 'ui/Colors';

.wrapper {
  position: relative;
  padding: 15px 20px;
  background: #ffffff;
  border-bottom: solid 1px $border;

  &.archived {
    .meta {
      color: $light;

      .circle {
        background: $light !important;
      }
    }
  }

  .actions {
    position: absolute;
    right: 15px;
    top: 5px;
  }
}

.meta {
  display: flex;
  align-items: center;

  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #ffffff;
    font-weight: $fontMedium;
    text-transform: uppercase;
    font-size: 12px;
  }

  .info {
    margin-left: 10px;

    .name {
      font-size: 19px;
      font-weight: $fontMedium;
    }

    .text {
      font-size: 13px;
      color: $dark;
      margin-bottom: 1px;
    }
  }
}

.time {
  display: flex;
  align-items: center;
  margin-top: 20px;

  .minutes {
    margin-left: 5px;
    font-size: 16px;
  }
}

.header {
  margin-top: 30px;
  margin-bottom: 9px;
  color: $dark;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: $fontMedium;
}

.bar {
  flex: 1;
  max-width: 232px;
  border-radius: 4px;
  border: solid 1px $primary;
  height: 12px;
  overflow: hidden;

  .fill {
    height: 100%;
    background: $primary;
  }
}

.expand {
  user-select: none;
  margin-top: 20px;
  color: $link;
  font-weight: $fontMedium;
  font-size: 11px;
  cursor: pointer;
  text-transform: uppercase;
}

.patients {
  display: flex;
  flex-wrap: wrap;
  margin: -4px -6px;

  .item {
    padding: 6px 7px 5px 7px;
    margin: 4px 6px;
    font-size: 13px;
    border-radius: 4px;
    border: solid 1px $border;
    background: $hover;
    cursor: pointer;

    &:hover {
      color: $link;
    }
  }
}

@media (min-width: $mobileLimit) {
  .patients {
    max-width: 75%;
  }
}
