@import 'ui/Colors';

@mixin common-padding {
  padding-left: 20px;
  padding-right: 20px;
}

.wrapper {
  border-bottom: solid 1px $border;
  padding-top: 17px;
  padding-bottom: 15px;
}

.title {
  @include common-padding;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;

  span {
    font-size: 18px;
    font-weight: $fontMedium;
  }
}

.appointment {
  @include common-padding;
  margin-bottom: 20px;
  font-size: 14px;

  .details {
    margin-top: 2px;

    &.cancelled {
      span {
        color: $attention;
      }
    }

    span {
      color: $link;
      cursor: pointer;
    }
  }
}

.actions {
  @include common-padding;
  margin-top: 15px;
  display: flex;
  align-items: center;
}

@media (min-width: $mobileLimit) {
  .wrapper {
    border-top: solid 1px $border;
    border-bottom: none;
  }

  .title {
    span {
      font-size: 19px;
    }
  }

  .appointment {
    font-size: 15px;
  }
}
