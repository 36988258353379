@import 'ui/Colors';

%fab {
  user-select: none;
  right: 20px;
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 55px;
  height: 55px;
  z-index: 99;
  border-radius: 50%;
  background: $secondary;
  box-shadow: 0 12px 14px 0 rgba(49, 54, 57, 0.2);
}

.mainFab {
  @extend %fab;
  position: fixed;
  bottom: 90px;
}

.overlay {
  z-index: 100;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #111722;
  opacity: 0.85;
  animation: showOverlay 0.2s forwards;
}

.items {
  z-index: 101;
  position: fixed;
  bottom: 90px;
  right: 20px;

  .item {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &:not(:last-child) {
      margin-bottom: 25px;
    }

    span {
      padding-right: 15px;
      font-size: 16px;
      animation: fadeIn 0.2s forwards;
      color: #ffffff;
    }

    .extraFab {
      @extend %fab;
      animation: zoomIn 0.2s forwards;

      &.close {
        background: #ffffff;
        animation: none;

        > div {
          animation: zoomIn 0.2s forwards;
        }
      }
    }
  }
}

@media print {
  .mainFab {
    display: none;
  }
}

@keyframes showOverlay {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.85;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
