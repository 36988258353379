@import 'ui/Colors';

.overlay {
  position: fixed;
  overflow: hidden;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: #111722;
  opacity: 0.85;
  transform: translateZ(0);
  animation: showOverlay 0.2s forwards;

  &.closing {
    animation: hideOverlay 0.2s forwards;
  }
}

@keyframes showOverlay {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.85;
  }
}

@keyframes hideOverlay {
  0% {
    opacity: 0.7;
  }

  100% {
    opacity: 0;
  }
}
