@import 'ui/Colors';

.mobileWrapper {
  padding: 20px;
}

.action {
  margin: auto;
  margin-top: 35px;
  width: 50%;
}

.success {
  font-size: 19px;
  text-align: center;
}

@media (min-width: $mobileLimit) {
  .action {
    width: 100%;
  }
}
