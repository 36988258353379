@import 'ui/Colors';

.form {
  .close {
    display: flex;
    align-items: center;
    margin-left: -4px;
    height: 100%;
    padding-top: 19px;
  }
}

.addAnother {
  display: inline-block;
  font-size: 11px;
  color: $link;
  font-weight: $fontMedium;
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 35px;
}

@media (min-width: $mobileLimit) {
  .wrapper {
    border-bottom: solid 1px $border;
  }

  .form {
    display: flex;

    .close {
      margin-left: 0;
    }
  }

  .addAnother {
    margin-bottom: 25px;
  }
}

@media (min-width: 1000px) {
  .item {
    min-width: 265px;
    max-width: 265px;
  }
}
