@import 'ui/Colors';

%fillIn {
  display: flex;
  align-items: center;
  height: 100%;
  padding-top: 4px;
}

.wrapper {
  display: block;
  margin-bottom: 35px;
  height: 70px;
  background: #ffffff;
  box-shadow: 0 2px 2px rgba(118, 127, 149, 0.15);

  .innerWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    padding-left: 35px;
    padding-right: 35px;
  }
}

.title {
  @extend %fillIn;
  justify-content: flex-start;

  .homepage {
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 18px;

    &.clickable {
      cursor: pointer;
    }

    .logo {
      width: 32px;
      height: 32px;
      margin-right: 12px;
    }
  }

  .extraTitle {
    font-size: 17px;
    font-weight: $fontMedium;
    cursor: text;
    border-left: solid 1px $border;
    padding-left: 21px;
    margin-left: 21px;
  }
}

.rightContent {
  display: flex;
  height: 100%;
}

.navItem {
  user-select: none;
  @extend %fillIn;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    color: $primary;
    transition: 0.25s ease;

    .moreItems {
      display: block;
    }
  }

  &.selected {
    color: $primary;
  }

  .moreItems {
    display: none;
    position: absolute;
    left: -25px;
    top: 65px;
    background: #ffffff;
    z-index: 99;
    width: 150px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid $border;
    border-radius: 4px;
    padding: 12px;

    .arrow {
      position: absolute;
      left: 65px;
      top: -6px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid $dark;
    }

    .item {
      color: $dark;
      font-size: 16px;
      cursor: pointer;

      &:not(:last-child) {
        margin-bottom: 6px;
      }

      &:hover {
        color: $primary;
        transition: 0.25s ease;
      }
    }
  }
}

.more {
  @extend %fillIn;
  margin-left: 40px;

  > :not(:last-child) {
    margin-right: 20px;
  }
}

@media (min-width: 1240px) {
  .wrapper {
    .innerWrapper {
      margin: auto;
      width: 1240px;
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}

@media (min-width: 1310px) {
  .wrapper {
    .innerWrapper {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@media print {
  .wrapper {
    display: none;
  }
}
