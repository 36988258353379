@import 'ui/Colors';

.values {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
}

.value {
  user-select: none;
  display: flex;
  align-items: center;
  height: 25px;
  padding-left: 11px;
  padding-right: 11px;
  margin-bottom: 5px;
  background: $hover;
  border-radius: 7px;
  border: solid 1px $border;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.label {
  font-size: 11px;
  font-weight: $fontMedium;
  text-transform: uppercase;
  padding-right: 12px;
}

.close {
  margin-top: -2px;
}
