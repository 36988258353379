$primary: #46ac5a;
$secondary: #01325f;
$light: #949bac;
$dark: #6e7482;
$attention: #d82b2b;
$warning: #f37a51;

$text: #1a1c1f;
$border: #dce0e7;
$hover: #f7f7f7;
$link: #23b0ff;
$linkHover: darken($link, 20%);
$background: #eceff3;

$fontLight: 300;
$fontNormal: 400;
$fontMedium: 500;
$fontBold: 700;

$tabletLimit: 700px;
$mobileLimit: 800px;

@mixin lo-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin lo-advance-elipsis($clamp: 1) {
  // Only not working in firefox but it just not contains ... at the end
  display: -webkit-box;
  -webkit-line-clamp: $clamp;
  -webkit-box-orient: vertical;
}

@mixin lo-hide-scrollbar {
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
