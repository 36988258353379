@import 'ui/Colors';

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $secondary;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    background: $dark;
  }
}
