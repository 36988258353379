@import 'ui/Colors';

.wrapper {
  display: block;
  position: relative;
  user-select: none;
}

.dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 125px;
  height: 45px;
  padding-left: 20px;
  padding-right: 14px;
  border: solid 1px $border;
  background: #ffffff;
  border-radius: 3px;
  cursor: pointer;

  .title {
    font-size: 15px;
    margin-right: 12px;
  }

  &:hover {
    background: darken(#ffffff, 5%);
  }
}

.box {
  top: 32px;
  position: absolute;
  z-index: 1;
  width: 165px;
  border-left: solid 1px $border;
  border-right: solid 1px $border;
  border-top: solid 1px $border;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  &.withTitle {
    top: 45px;
    left: 0;
  }

  .item {
    display: flex;
    align-items: center;
    padding-left: 12px;
    padding-right: 8px;
    height: 40px;
    border-bottom: solid 1px $border;
    font-size: 15px;
    background: #ffffff;
    cursor: pointer;

    &.normal {
      &:hover {
        background: $primary;
        color: #ffffff;
      }
    }

    &.attention {
      &:hover {
        background: $attention;
        color: #ffffff;
      }
    }
  }
}
