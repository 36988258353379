@import 'ui/Colors';

.wrapper {
  padding-top: 4px;
}

.reportType {
  @include lo-advance-elipsis;
  overflow: hidden;
  max-height: 22px;
  font-size: 17px;
}

.date {
  font-size: 13px;
  color: $light;
}
