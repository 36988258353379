@import 'ui/Colors';

.addNew {
  display: flex;
  align-items: center;
  height: 100%;
  padding-top: 19px;

  .circle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px $link;
    min-width: 32px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background: $hover;
      box-shadow: 0 4px 6px 0 rgba(49, 54, 57, 0.2);
    }

    &:active {
      top: 1px;
    }
  }
}

@media (min-width: $mobileLimit) {
  .addNew {
    margin-left: -10px;
  }
}
