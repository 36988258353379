@import 'ui/Colors';

.wrapper {
  display: flex;
  animation: fadeIn 1s forwards;

  &:not(:last-child) {
    z-index: 1;
  }
}

.donut {
  max-width: 150px;
  width: 50%;
  display: inline-block;
}

.list {
  width: 50%;
  margin-left: 25px;

  &.empty {
    filter: blur(3px);
    pointer-events: none;
    user-select: none;
  }
}

.listItem {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  height: 18px;
  overflow: hidden;
  cursor: default;

  .box {
    min-width: 16px;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    margin-right: 6px;
  }

  .text {
    @include lo-advance-elipsis(1);
    font-size: 15px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
