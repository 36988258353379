.textArea {
  border: none;
  padding: 0;
  font-size: 16px;
  min-height: 20px;

  &.right {
    text-align: right;
  }
}
