@import 'ui/Colors';

@mixin side-padding {
  padding-left: 20px;
  padding-right: 20px;
}

.hint {
  @include side-padding;
  font-size: 15px;
  color: $dark;
}
