@import 'ui/Colors';

.topSection {
  display: flex;
  justify-content: space-between;
  padding-bottom: 13px;
  border-bottom: solid 1px $border;
  margin-bottom: 14px;

  .logo {
    flex: 1;
    margin-right: 20px;
  }

  .organisationInfo {
    flex: 3;

    .meta {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 8px;

      .label {
        flex: 1;
        text-align: right;
        font-size: 15px;
        margin-right: 14px;
      }
    }
  }
}

.middleSection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;

  .title {
    text-transform: uppercase;
    font-weight: $fontMedium;
    font-size: 13px;
    margin-bottom: 11px;
  }

  .info {
    font-size: 15px;
    margin-bottom: 2px;
  }

  .block {
    flex: 1;

    &:first-child {
      margin-right: 10px;
    }

    &:last-child {
      margin-left: 10px;
    }

    &.right {
      .title,
      .info {
        text-align: right;
      }
    }
  }
}

.reportSection {
  position: relative;
  margin-bottom: 40px;

  .top {
    position: relative;
    padding-bottom: 2px;
    border-bottom: solid 1px $border;
    margin-bottom: 8px;

    .title {
      font-size: 13px;
      text-transform: uppercase;
      color: $primary;
      font-weight: $fontMedium;
    }

    .remove {
      position: absolute;
      right: -4px;
      top: -5px;
    }
  }

  .content {
    font-size: 16px;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
}
