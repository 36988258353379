.custom {
  position: relative;
  display: flex;
}

.picker {
  width: 150px;
  margin-right: 16px;
}

.close {
  position: absolute;
  right: -39px;
  top: -6px;
}

@media (min-width: 1100px) {
  .picker {
    width: 200px;
  }
}
