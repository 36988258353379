@import 'ui/Colors';

.headerContainer {
  background: $background;
  min-height: 5px;
  margin-bottom: 11px;

  .header {
    user-select: none;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
}

.resource {
  @include lo-advance-elipsis(2);
  text-align: center;
  font-size: 15px;
}

.time {
  user-select: none;
  font-size: 15px;
  padding-left: 20px;
}

.row {
  display: flex;
  margin-bottom: 1px;
}

.cell {
  position: relative;
  display: flex;
}

@media (min-width: $mobileLimit) {
  .time {
    padding-left: 10px;
  }
}
