@import 'ui/Colors';

.top {
  display: flex;
  margin-bottom: 7px;

  .backLink {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      margin-left: 6px;
      color: $link;
      font-size: 15px;
    }
  }
}

.wrapper {
  display: flex;
}

.document {
  flex: 1;
  max-width: 800px;
  padding: 50px 35px;
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  position: relative;
}

.info {
  margin-top: 9px;
  font-size: 13px;
  color: $dark;
}
