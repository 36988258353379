@import 'ui/Colors';

.debtWrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .debt {
    font-size: 15px;
    color: $attention;
  }
}
