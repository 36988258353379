@import 'ui/Colors';

.wrapper {
  background: #ffffff;
  padding: 20px;
  margin-bottom: 15px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;

  .titleText {
    font-weight: $fontMedium;
    font-size: 17px;
  }
}

@media (min-width: $mobileLimit) {
  .wrapper {
    padding: 13px;
    margin-bottom: 40px;
    background: #ffffff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
  }
}
