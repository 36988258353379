@import 'ui/Colors';

.debt {
  color: $attention;
}

.patient {
  padding: 10px;
  flex-grow: 0;
  max-width: 33.33%;
  flex-basis: 33.33%;

  .inner {
    padding: 4px 11px 8px 11px;
    height: 275px;
    background: #ffffff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    border: solid 1px $border;

    &:hover {
      background: $hover;
      cursor: pointer;
    }
  }
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin-top: -9px;
  margin-bottom: 15px;
  text-align: center;

  .name {
    @include lo-advance-elipsis(1);
    overflow: hidden;
    margin-top: 7px;
    margin-bottom: 1px;
    font-size: 19px;
  }

  .diagnose {
    @include lo-advance-elipsis(2);
    height: 32px;
    font-size: 13px;
    color: $dark;
  }
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 35px;
  max-width: 35px;
  height: 35px;
  border-radius: 50%;
  font-size: 11px;
  font-weight: $fontMedium;
  color: #ffffff;
}

.content {
  .item {
    overflow: hidden;
    display: flex;
    align-items: center;
    font-size: 15px;
    margin-bottom: 7px;

    .value {
      @include lo-advance-elipsis(1);
      max-height: 20px;
      padding-left: 7px;
      margin-bottom: -1px;
    }
  }
}

.debtWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 15px;
}

@media (min-width: 1025px) {
  .patient {
    max-width: 25%;
    flex-basis: 25%;
  }
}
