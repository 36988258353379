@import 'ui/Colors';

.mobileInput {
  display: flex;
  align-items: center;
  border-bottom: solid 1px $border;
  font-size: 17px;
  padding-right: 24px;
  height: 35px;
  width: 100%;
  cursor: pointer;
  background: #ffffff;
}

.wrapper {
  user-select: none;
  position: relative;

  .icon {
    position: absolute;
    right: 0;
    top: 7px;
    pointer-events: none;
  }
}

:global {
  .react-datepicker__input-container {
    input {
      border: solid 1px $border;
      border-radius: 2px;
      font-size: 17px;
      padding-left: 12px;
      padding-right: 24px;
      height: 45px;
      width: 100%;
      outline: none;
      cursor: pointer;
    }
  }

  .react-datepicker-popper {
    z-index: 100 !important;
    margin-top: -1px !important;
    border-color: $border !important;
    box-shadow: 0 6px 18px 1px rgba(0, 0, 0, 0.12);
    min-height: 276px !important;
    max-height: 276px !important;

    *:not(button) {
      font-family: 'Roboto', sans-serif !important;
      border-color: $border !important;
    }

    button:active {
      outline: none !important;
    }
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: block !important;
  }

  .react-datepicker__month-select,
  .react-datepicker__year-select {
    outline: none;
  }

  .react-datepicker {
    border-radius: 0 !important;
  }

  .react-datepicker__month-container,
  .react-datepicker__time-container {
    min-height: 276px !important;
    max-height: 276px !important;
    overflow: hidden !important;
  }

  .react-datepicker__triangle {
    display: none;
  }

  button.react-datepicker__navigation {
    line-height: 0;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    outline: none;
  }

  .react-datepicker__navigation--next {
    border-left-color: $dark !important;
  }

  .react-datepicker__navigation--previous {
    border-right-color: $dark !important;
  }

  .react-datepicker__current-month {
    text-transform: capitalize;
  }

  .react-datepicker__header {
    background: #ffffff !important;
  }

  .react-datepicker__time-list {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__time-list-item--selected,
  .react-datepicker__day--keyboard-selected {
    background: $primary !important;
  }

  .react-datepicker__time-list-item--disabled {
    display: none !important;
  }
}

@media (min-width: $mobileLimit) {
  .mobileInput {
    border: solid 1px $border;
    border-radius: 2px;
    padding-left: 12px;
    height: 45px;
  }

  .wrapper {
    .icon {
      right: 12px;
      top: 11px;
    }
  }
}
