@import 'ui/Colors';

.wrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 325px;
  z-index: 101;
  background: #ffffff;
  border-top: solid 1px $border;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  animation: slideUp 0.25s forwards;
  overflow-y: auto;

  &.closing {
    animation: slideDown 0.25s forwards;
  }
}

.item {
  user-select: none;
  display: flex;
  align-items: center;
  height: 65px;
  font-size: 17px;
  padding-left: 20px;
  padding-right: 20px;

  &.selected {
    font-weight: $fontBold;
  }

  &.attention {
    color: $attention;
  }

  &.empty {
    color: $light;
  }
}

@keyframes slideUp {
  0% {
    opacity: 0.1;
    bottom: -100%;
  }

  100% {
    opacity: 1;
    bottom: 0;
  }
}

@keyframes slideDown {
  0% {
    opacity: 0.8;
    bottom: 0;
  }

  100% {
    opacity: 0;
    bottom: -100%;
  }
}
