@import 'ui/Colors';

.wrapper {
  margin: auto;
  max-width: 600px;
}

.error {
  font-size: 15px;
  font-weight: $fontMedium;
  color: $attention;
}

.form {
  padding-top: 12px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 7px;
}

@media (min-width: $mobileLimit) {
  .wrapper {
    min-width: 600px;
  }

  .form {
    padding: 0;
  }
}
