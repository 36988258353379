@import 'ui/Colors';

.service {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    .name {
      display: flex;
      align-items: center;
      font-size: 15px;

      .invoiced {
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .scheduled {
      margin-top: 3px;
      font-size: 12px;
      color: $light;

      &.not {
        color: $attention;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    font-size: 15px;

    .debt {
      margin-left: 15px;
    }

    .invoice {
      color: $link;
      margin-left: 15px;

      &:hover {
        color: $linkHover;
      }
    }

    .date {
      margin-left: 15px;
      font-weight: $fontMedium;
    }
  }
}

.debt {
  color: $attention;
}
