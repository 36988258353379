@import 'ui/Colors';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 15px;
  font-weight: $fontMedium;
  background: $attention;
  min-height: 30px;
  padding: 3px 5px;
  width: 100%;
  border-radius: 3px;
  margin-bottom: 17px;
}

@media (min-width: $mobileLimit) {
  .wrapper {
    margin-top: -25px;
  }
}
