@import 'ui/Colors';

.wrapper {
  position: relative;

  .indicator {
    user-select: none;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -10px;
    right: -10px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: $attention;
    color: #ffffff;
    font-size: 10px;
  }
}
