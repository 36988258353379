@import 'ui/Colors';

.wrapper {
  position: relative;
}

.loadingState {
  padding-top: 40px;
  padding-bottom: 40px;
}

.failedState {
  padding: 40px 20px;
}

.menu {
  position: absolute;
  right: 0;
  top: 45px;
  background: #ffffff;
  z-index: 99;
  max-height: 450px;
  width: 350px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid $border;
  border-bottom: none;
  overflow-y: auto;
}
