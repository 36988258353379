@import 'ui/Colors';

.roles {
  .role {
    width: 350px;
    padding: 20px 28px;
    border: solid 1px $border;
    background: #ffffff;

    &:not(:last-child) {
      border-bottom: none;
    }

    .head {
      display: flex;
      justify-content: space-between;
      font-size: 21px;
      font-weight: $fontMedium;
      margin-bottom: 3px;

      .payed {
        padding: 3px 7px;
        font-size: 15px;
        background: $primary;
        color: #ffffff;
        border-radius: 2px;
      }
    }

    .price {
      display: flex;
      align-items: flex-end;
      font-size: 31px;
      color: $primary;
      padding-bottom: 25px;
      margin-bottom: 15px;
      border-bottom: solid 1px $border;

      span {
        padding-left: 4px;
        font-size: 15px;
        padding-bottom: 4px;
        color: $dark;
      }
    }

    .included {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: $dark;

      &:not(:last-child) {
        padding-bottom: 6px;
      }

      span {
        padding-left: 8px;
      }
    }
  }
}

.fullPriceWrapper {
  background: #ffffff;
  padding: 20px 28px;
  border-left: solid 1px $border;
  border-right: solid 1px $border;
  border-bottom: solid 1px $border;

  .fullPrice {
    display: flex;
    align-items: center;
    font-size: 21px;
    font-weight: $fontMedium;
    margin-bottom: 15px;
  }

  .savings {
    display: flex;

    .save {
      display: flex;
    }
  }
}

.attention {
  max-width: 700px;
  margin-top: 5px;
  color: $attention;
  font-size: 14px;
  padding-left: 2px;
}

@media (min-width: $mobileLimit) {
  .roles {
    display: flex;

    .role {
      &:not(:last-child) {
        border-bottom: solid 1px $border;
        border-right: none;
      }
    }
  }
}
