@import 'ui/Colors';

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  padding: 0 20px;
  background: #f5f5f5;
  overflow: hidden;

  .group {
    @include lo-hide-scrollbar;
    display: flex;
    align-items: center;
    flex: 1;
    overflow-x: auto;
  }

  .item {
    user-select: none;
    font-size: 13px;
    font-weight: $fontMedium;
    cursor: pointer;
    text-transform: uppercase;
    padding: 5px 11px;
    border-radius: 6px;

    &:not(:last-child) {
      margin-right: 15px;
    }

    &.selected {
      color: $primary;
      background: rgba($primary, 0.2);
      transition: 0.25s ease;
    }
  }
}

@media (min-width: $mobileLimit) {
  .navigation {
    align-items: flex-end;
    padding: 20px 0 9px 0;
    background: initial;
    min-height: 44px;

    .group {
      flex: initial;
      overflow-x: initial;
    }

    .item {
      padding: 0;
      border-radius: 0;

      &:not(:last-child) {
        margin-right: 30px;
      }

      &:hover {
        color: $primary;
        transition: 0.25s ease;
      }

      &.selected {
        background: initial;
      }
    }
  }

  .view {
    min-height: 450px;
  }
}
