@import 'ui/Colors';

.wrapper {
  padding-top: 10px;

  .notes {
    flex: 1;
  }

  .chart {
    flex: 1;
    display: flex;
    justify-content: center;
  }
}

@media (min-width: $mobileLimit) {
  .wrapper {
    display: flex;
    flex-direction: row-reverse;

    .chart {
      padding-top: 10px;
      padding-right: 5px;
    }
  }
}
