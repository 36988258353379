@import 'ui/Colors';

.bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding-left: 20px;
  padding-right: 5px;
}

.date {
  font-size: 15px;
  font-weight: $fontMedium;
}

.arrows {
  display: flex;
}

@media (min-width: $mobileLimit) {
  .bar {
    padding-left: 10px;
  }
}

@media print {
  .arrows {
    display: none;
  }
}
