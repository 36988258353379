@import 'ui/Colors';

.wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &.extraSpace {
    padding-top: 20px;
  }
}

.image {
  height: 125px;
  width: auto;
  margin-bottom: 35px;
}

.intro {
  max-width: 300px;
  font-size: 17px;
  text-align: center;
  margin-bottom: 15px;
}

@media (min-width: $mobileLimit) {
  .wrapper {
    flex-direction: row;
    padding-top: 55px;

    &.extraSpace {
      padding-top: 120px;
    }
  }

  .image {
    margin-right: 25px;
    margin-bottom: 0;
  }
}
