@import 'ui/Colors';

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: solid 1px $border;
  background: #ffffff;
  height: 35px;
  font-size: 17px;
  cursor: pointer;
  padding-right: 35px;

  .placeholder {
    overflow: hidden;
    @include lo-advance-elipsis(1);
  }

  .icon {
    position: absolute;
    right: 0;
    top: 7px;
  }
}

@media (min-width: $mobileLimit) {
  .wrapper {
    border: solid 1px $border;
    height: 45px;
    padding-left: 12px;

    .icon {
      right: 12px;
      top: 11px;
    }
  }
}
