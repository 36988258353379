@import 'ui/Colors';

.wrapper {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 9px;
  background: #ffffff;
  border: solid 1px $border;
  border-radius: 2px;

  &:hover {
    cursor: pointer;
    background: $hover;
  }

  &:active {
    top: 1px;
  }
}
