@import 'ui/Colors';

.top {
  display: flex;
  margin-bottom: 2px;
  justify-content: space-between;
  font-size: 15px;
  font-weight: $fontMedium;
  color: $light;
  padding-right: 25px;
}

.wrapper {
  background: #ffffff;
  margin-bottom: 35px;

  &.addPadding {
    padding-top: 20px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 15px;
  }
}

@media (min-width: $mobileLimit) {
  .wrapper {
    border-radius: 2px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  }
}
