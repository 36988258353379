@import 'ui/Colors';

.panel {
  flex: 1;
  display: flex;
  padding: 15px;
  margin-bottom: 30px;
  background: #ffffff;
  border-radius: 4px;
}

.group {
  flex: 1;

  &:not(:last-child) {
    margin-right: 35px;
  }

  .row {
    flex: 1;
    display: flex;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .row > :not(:last-child) {
    margin-right: 12px;
  }

  .row > div {
    flex: 1;
  }
}

.header {
  display: none;
}

@media (min-width: $mobileLimit) {
  .header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 12px;

    > :not(:last-child) {
      margin-right: 12px;
    }
  }
}
