@import 'ui/Colors';

.text {
  font-size: 16px;
}

.footer {
  margin-top: 6px;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: $light;

  span {
    &:not(:last-child) {
      &::after {
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        margin-left: 6px;
        margin-right: 6px;
        margin-bottom: 2px;
        border-radius: 50%;
        background-color: $dark;
      }
    }
  }
}
