@import 'ui/Colors';

.outerWrapper {
  position: relative;

  &:hover {
    .remove {
      display: block;
      z-index: 1;
    }

    .image {
      opacity: 0.3;
    }
  }

  .remove {
    display: none;
    position: absolute;
    right: -10px;
    top: -18px;
  }

  .error {
    position: absolute;
    z-index: 1;
    left: 120px;
    right: -90px;
    bottom: 55px;
    color: $attention;
    font-size: 13px;
  }
}

%wrapper {
  width: 115px;
  height: 115px;
  border-radius: 4px;
}

.image {
  @extend %wrapper;
  object-fit: contain;
}

.uploader {
  @extend %wrapper;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: solid 1px $border;
  cursor: pointer;

  span {
    text-transform: uppercase;
    padding-top: 4px;
    font-size: 13px;
    font-weight: $fontMedium;
  }
}

.progress {
  @extend %wrapper;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: solid 1px $border;
  font-size: 23px;
}
