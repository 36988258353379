@import 'ui/Colors';

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  text-align: center;
  margin-bottom: 25px;
  font-size: 23px;
  font-weight: $fontMedium;
}

.description {
  text-align: center;
  font-size: 19px;
}

.actions {
  display: flex;
  justify-content: center;
  margin-top: 17px;
}

@media (min-width: $mobileLimit) {
  .wrapper {
    justify-content: space-between;
  }

  .actions {
    margin-top: 0;
  }
}
