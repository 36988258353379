@import 'ui/Colors';

.wrapper {
  text-align: center;

  &.padding {
    padding-top: 150px;
  }
}

.info {
  color: $attention;
  font-size: 17px;
}
