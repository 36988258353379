@import 'ui/Colors';

.item {
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  cursor: pointer;
  user-select: none;
}

.title {
  display: flex;
  align-items: center;

  span {
    font-size: 17px;
    color: $light;
    padding-right: 3px;
  }
}
