@import 'ui/Colors';

.form {
  padding-top: 27px;
  padding-left: 20px;
  padding-right: 20px;
}

.includesAmount {
  display: flex;
  align-items: center;
}

.addActions {
  display: flex;
}

.treatments {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    color: $light;
    border-top: solid 1px $border;
    border-bottom: solid 1px $border;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 15px;
    font-weight: $fontMedium;
  }

  .treatment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: solid 1px $border;

    &:hover {
      background: $hover;

      .name {
        max-width: 190px;
      }

      .actions {
        display: flex;
        align-items: center;
        margin-left: 18px;

        :not(:last-child) {
          margin-right: 8px;
        }
      }
    }

    .name {
      max-width: 245px;
      font-size: 17px;
    }

    .includes {
      display: flex;
      align-items: center;
      font-weight: $fontLight;

      &.included {
        font-weight: $fontMedium !important;
      }
    }

    .actions {
      display: none;
    }
  }
}
