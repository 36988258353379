@import 'ui/Colors';

.form {
  height: 100%;

  form {
    height: 100%;
  }
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  text-align: center;
  margin-bottom: 25px;
  font-size: 23px;
  font-weight: $fontMedium;
}

.reason {
  min-height: 100px;
  max-height: 100px;
  border: solid 1px $border;
}

.clearDebt {
  margin-top: 10px;
}

.actions {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

@media (min-width: $mobileLimit) {
  .title {
    margin-bottom: 20px;
  }
}
