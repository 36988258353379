@import 'ui/Colors';

.button {
  font-family: 'Roboto', 'sans-serif' !important;
  font-weight: $fontMedium;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
  white-space: nowrap;

  // Action types

  &.primary {
    background: $primary;
    border: solid 1px darken($primary, 5%);
    color: #ffffff;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }

  &.mobile {
    background: $primary;
    border: solid 1px darken($primary, 5%);
    color: #ffffff;
    min-width: 75px !important;
    height: 30px !important;
  }

  &.normal {
    background: #ffffff;
    border: solid 1px $border;
    color: $light;
    font-weight: $fontNormal;
  }

  &.attention {
    background: #ffffff;
    border: solid 1px $border;
    color: $attention;
    font-weight: $fontNormal;
  }

  &.danger {
    background: $attention;
    border: solid 1px darken($attention, 5%);
    color: #ffffff;
  }

  // Sizes

  &.default {
    min-width: 125px;
    padding-left: 12px;
    padding-right: 12px;
    height: 45px;
    font-size: 15px;
  }

  &.small {
    min-width: 115px;
    padding-left: 9px;
    padding-right: 9px;
    height: 35px;
    font-size: 14px;
  }

  // Rest

  &.fill {
    width: 100%;
  }

  &.disabled {
    background: #cccccc !important;
    color: #ffffff !important;
    border: none !important;
    box-shadow: none !important;
    cursor: auto !important;

    &:active {
      top: 0 !important;
    }

    &:hover {
      background: #cccccc !important;
    }
  }
}

.button:active {
  position: relative;
  top: 1px;
}

.button:hover {
  &.primary {
    background: darken($primary, 5%);
  }

  &.normal {
    background: #fafafa;
  }

  &.attention {
    background: $attention;
    color: #ffffff;
  }

  &.danger {
    background: darken($attention, 5%);
  }
}

.button:not(:last-child) {
  margin-right: 16px;
}
