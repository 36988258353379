@import 'ui/Colors';

.textArea {
  outline: none;
  width: 100%;
  border: none;
  resize: none;
  min-height: 125px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 0;
  padding-right: 0;
  font-size: 17px;
  font-family: 'Roboto', 'sans-serif' !important;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $light;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $light;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $light;
  }
}

@media (min-width: $mobileLimit) {
  .textArea {
    border: solid 1px $border;
    padding-left: 8px;
    padding-right: 8px;
  }
}
