@import 'ui/Colors';

.appointment {
  display: flex;
  flex-direction: column;
  width: 225px;
  min-width: 225px;
  height: 80px;
  padding: 6px 6px 6px 8px;
  margin-right: 15px;
  margin-bottom: 15px;
  border-radius: 4px;
  border: solid 1px $border;
  transition: 0.2s ease;

  &:hover {
    cursor: pointer;
    background: $hover;
  }

  &.cancelled {
    .date {
      span {
        color: $attention;
      }
    }

    .name {
      color: $light;
    }
  }

  .date {
    display: flex;
    align-items: center;
    padding-bottom: 1px;

    span {
      padding-left: 2px;
      font-size: 13px;
      font-weight: $fontMedium;
    }
  }

  .name {
    max-height: 38px;
    min-height: 38px;
    overflow: hidden;
    @include lo-advance-elipsis(2);
    font-size: 15px;
    padding-right: 15px;
  }

  .users {
    display: flex;

    .label {
      @include lo-ellipsis;
      font-weight: $fontMedium;
      color: #ffffff;
      font-size: 10px;
      padding: 2px 3px;
      border-radius: 3px;

      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }
}
