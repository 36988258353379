@import 'ui/Colors';

.details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 45px;

  .organisation {
    display: flex;
    flex: 2;
    margin-right: 60px;

    .business {
      margin-left: 30px;

      .title {
        font-size: 17px;
        text-transform: uppercase;
        font-weight: $fontMedium;
        margin-bottom: 15px;
      }

      .info {
        font-size: 15px;
        margin-bottom: 4px;
      }
    }
  }

  .right {
    flex: 1;

    .head {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .badge {
        padding: 5px 8px;
        text-transform: uppercase;
        color: #ffffff;
        font-size: 13px;
        font-weight: $fontBold;
        border-radius: 4px;
      }

      .date {
        padding-top: 5px;
        font-size: 15px;
        font-weight: $fontMedium;
      }
    }

    .receiver {
      padding-top: 30px;
      border-bottom: solid 1px $border;
      margin-bottom: 15px;
    }
  }
}

.groupingAction {
  padding-bottom: 3px;
}

.services {
  border-top: solid 1px $border;
  margin-bottom: 10px;

  .service {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px $border;
    min-height: 40px;
    padding: 4px 0;
    font-size: 17px;

    .right {
      display: flex;
      align-items: center;
      margin-left: 35px;

      .money {
        width: 130px;
        text-align: right;
      }
    }
  }
}

.total {
  margin-bottom: 45px;
  display: flex;
  justify-content: flex-end;
  font-weight: $fontBold;
  font-size: 17px;
}

.instructions {
  padding-top: 5px;
  border-top: solid 1px $border;
}
