@import 'ui/Colors';

.wrapper {
  user-select: none;
  display: flex;
  align-content: stretch;

  &.pointer {
    cursor: pointer;
  }

  &.expand {
    padding: 15px;
  }

  .icon {
    display: flex;
    align-items: flex-end;
    font-weight: $fontMedium;

    i {
      font-weight: $fontMedium;
      color: $text;
    }
  }
}
