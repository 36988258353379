@import 'ui/Colors';

.tabbar {
  user-select: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 65px;
  height: 65px;
  border-top: solid 1px $border;
  z-index: 99;
  display: flex;
  justify-content: space-around;
  background: #ffffff;

  .item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: $fontBold;

    .title {
      font-size: 13px;
      color: $text;
      padding-top: 2px;

      &.active {
        color: $primary;
      }
    }
  }
}

.placeholder {
  margin-top: 95px;
  height: 65px;
  min-height: 65px;
}

@media print {
  .tabbar,
  .placeholder {
    display: none;
  }
}
