@import 'ui/Colors';

.outer {
  position: relative;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  color: $light;

  &.normal {
    font-size: 15px;
  }

  &.error {
    color: $attention;
  }
}

.hint {
  color: $light;
  position: absolute;
  left: 0;
  top: -13px;
  font-size: 13px;
}

.new {
  font-size: 11px;
  font-weight: $fontMedium;
  text-transform: uppercase;
  padding: 3px 6px;
  margin-bottom: 2px;
  border-radius: 4px;
  background: $warning;
  color: #ffffff;
}
