@import 'ui/Colors';

.wrapper {
  flex: 1;
  display: flex;
  justify-content: space-between;
  font-size: 17px;
  overflow: hidden;
}

.name {
  @include lo-ellipsis;
}

.meta {
  display: flex;
  min-width: 250px;
  justify-content: flex-end;

  .treatments {
    margin-right: 5px;
  }

  .price {
    min-width: 135px;
    text-align: right;
    font-weight: $fontMedium;
  }
}
