@import 'ui/Colors';

.item {
  padding-bottom: 10px;
}

.error {
  display: flex;
  align-items: center;
  margin-top: 21px;
  margin-bottom: -9px;

  span {
    font-size: 15px;
    color: $attention;
    padding-right: 4px;
  }
}

.actions {
  margin-top: 25px;
}

@media (min-width: $mobileLimit) {
  .item {
    padding-bottom: 0;
  }
}

@media (min-width: 1000px) {
  .item {
    min-width: 400px;
    max-width: 400px;
  }

  .actions {
    margin-top: 35px;
  }
}
