@import 'ui/Colors';

.wrapper {
  position: relative;
}

.circle {
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #ffffff;
  border: solid 2px $primary;
  cursor: pointer;
  font-size: 12px;
  font-weight: $fontMedium;
  color: $primary;
}

.dropdown {
  position: absolute;
  right: 0;
  top: 45px;
  background: #ffffff;
  z-index: 99;
  width: 230px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid $border;
  padding: 12px;

  .name {
    font-size: 19px;
  }

  .businessName {
    font-size: 13px;
    color: $dark;
  }

  .items {
    border-top: solid 1px $border;
    margin-top: 9px;

    .item {
      margin-top: 6px;
      color: $dark;
      font-size: 16px;
      cursor: pointer;

      &:hover {
        color: $primary;
        transition: 0.25s ease;
      }

      &.accent {
        color: $link;
      }
    }
  }
}
