@import 'ui/Colors';

// For mobile

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 35px;
  width: 100%;
  font-size: 17px;
  border-bottom: solid 1px $border;

  .item {
    // Not working very well on mobile but who cares (currently longest value is diagnose)
    @include lo-ellipsis;
  }
}

// For desktop

.select {
  min-width: 150px;

  :global {
    .select__control {
      border-radius: 3px;
      border: solid 1px $border !important;
      height: 45px;
      background: #ffffff;
      box-shadow: none !important;

      .select__value-container {
        padding: 0 12px;
      }

      .select__placeholder,
      .select__single-value {
        color: $text;
        margin: 0;
      }
    }

    .select__menu {
      background: #ffffff;
      border: solid 1px $border;
      border-top: 0;
      z-index: 99;
      border-radius: 2px;
      margin-top: 0;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      .select__menu-list {
        padding: 0;
        max-height: 175px;
      }

      .select__option {
        height: 35px;
        padding: 8px 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .select__option--is-focused {
        background: lighten($primary, 35%);
        color: $text;
      }

      .select__option--is-selected {
        background: $primary;
        color: #ffffff;
      }
    }
  }
}

@media (min-width: 1100px) {
  .select {
    min-width: 200px;
  }
}
