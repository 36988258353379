@import 'ui/Colors';

.emptyState {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 27px;
  font-size: 17px;
}

.searchForm {
  padding: 27px 20px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: solid 1px $border;
  font-size: 17px;
}

.money {
  display: flex;
  justify-content: flex-end;
  min-width: 125px;
}
