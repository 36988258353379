@import 'ui/Colors';

.mobileWrapper {
  padding: 20px;
}

.box {
  margin: auto;
  display: flex;
  width: 100%;
  max-width: 1000px;
  height: 625px;
  background: #ffffff;
  border: 1px solid $border;
  border-bottom: 0;
  box-shadow: 0 0 74px rgba(0, 0, 0, 0.18);
  border-radius: 5px;
}

.promoWrap {
  position: relative;

  .promo {
    max-width: 530px;
    height: 100%;
    object-fit: cover;
  }

  .darkBg {
    position: absolute;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    left: 0;
    right: 0;
    bottom: 0;
    height: 177px;
    background: linear-gradient(0deg, #121921 0%, rgba(0, 0, 0, 0) 90.09%);

    .text {
      text-align: center;
      margin-bottom: 38px;

      .smallIntro {
        font-size: 16px;
        font-weight: $fontMedium;
        color: #ff8e36;
        margin-bottom: 9px;
      }

      .promoHeader {
        color: #ffffff;
        font-weight: $fontMedium;
        font-size: 24px;
      }
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-left: 27px;
  padding-right: 27px;

  .header {
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 7px;
    font-size: 23px;
    font-weight: $fontMedium;
  }

  .intro {
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 43px;
    font-size: 17px;
    color: $dark;
  }
}

.invited {
  margin-bottom: 15px;
  font-size: 15px;
  color: $primary;
}

.action {
  margin: auto;
  margin-top: 35px;
  width: 50%;
}

.terms {
  margin-top: 17px;
  font-size: 13px;
  text-align: center;

  span {
    color: $link;
  }
}

@media (min-width: $mobileLimit) {
  .action {
    width: 100%;
  }
}
