@import 'ui/Colors';

.wrapper {
  padding: 20px;
}

.head {
  padding-top: 20px;
  margin-bottom: 55px;

  .title {
    text-align: center;
    font-size: 27px;
    margin-bottom: 20px;
  }

  .intro {
    text-align: center;
    font-size: 17px;
    color: $dark;

    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }
}

.plans {
  display: flex;
  justify-content: center;
}

@media (min-width: $mobileLimit) {
  .wrapper {
    padding: 0;
  }
}
