@import 'ui/Colors';

.input {
  width: 100%;
  height: 100%;
  font-size: 15px;
  border: none;
  outline: none;
  padding: 0;
  font-family: 'Roboto', 'sans-serif' !important;

  &.normal {
    font-size: 15px;
    font-weight: $fontNormal;
    padding-top: 3px;
    padding-bottom: 3px;
    border-bottom: solid 1px $border;
  }

  &.title {
    font-size: 14px;
    font-weight: $fontMedium;
    border: none;
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $light;
    opacity: 1; /* Firefox */
    text-transform: initial;
    font-weight: $fontNormal;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $light;
    text-transform: initial;
    font-weight: $fontNormal;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $light;
    text-transform: initial;
    font-weight: $fontNormal;
  }
}
