@import 'ui/Colors';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  margin-bottom: 75px;
  padding-left: 35px;
  padding-right: 35px;
}

.title {
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;
  padding-bottom: 6px;
  font-size: 18px;
  cursor: pointer;

  .logo {
    width: 32px;
    height: 32px;
    margin-right: 12px;
  }
}

.right {
  display: flex;
  align-items: center;

  .question {
    margin-right: 20px;
    font-size: 16px;
    color: $dark;
  }
}

.mobileRight {
  display: flex;
  align-items: center;
  color: #ffffff;
}
