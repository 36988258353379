@import 'ui/Colors';

.left {
  min-width: 0;
  min-height: 0;
  flex: 5;
}

.right {
  min-width: 0;
  min-height: 0;
  flex: 2;
}

.moneyFlowChartTitle {
  margin-bottom: 35px;
  font-weight: $fontMedium;
  font-size: 19px;
  padding-left: 7px;
}

@media (min-width: 1100px) {
  .wrapper {
    display: flex;
  }

  .left {
    margin-right: 40px;
  }
}
