@import 'ui/Colors';

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  width: 470px;
  height: 400px;
  padding-left: 27px;
  padding-right: 27px;
  background: #ffffff;
  border: 1px solid $border;
  border-bottom: 0;
  box-shadow: 0 0 74px rgba(0, 0, 0, 0.18);
  border-radius: 5px;
}

.header {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 7px;
  font-size: 23px;
  font-weight: $fontMedium;
}

.intro {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 43px;
  font-size: 17px;
  color: $dark;
}
