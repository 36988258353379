.wrapper {
  width: 180px;
  display: flex;
  flex-wrap: wrap;
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  min-width: 32px;
  height: 32px;
  margin: 2px;
  cursor: pointer;
  border-radius: 3px;

  .selected {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #ffffff;
  }
}
