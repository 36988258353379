@import 'ui/Colors';

.wrapper {
  padding: 20px;
}

.list {
  display: flex;
  flex-wrap: wrap;
}
