@import 'ui/Colors';

.appointment {
  user-select: none;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
  font-size: 13px;
  padding: 7px 10px;
  border-radius: 5px;
  border-left: solid 5px $background;
  border-top: solid 1px $border;
  border-bottom: solid 1px $border;
  border-right: solid 1px $border;
  background-color: #ffffff;
  transform: translate3d(0, 0px, 0);
  transition: 0.25s ease;

  &:hover {
    width: 100% !important;
    left: 0 !important;
    right: 0 !important;
    transition: 0.25s ease;
    z-index: 2;
    min-height: 120px;
    cursor: pointer;
    background: $hover;
  }

  &.cancelled {
    .top,
    .info > div {
      opacity: 0.4;
    }
  }

  .isCancelled {
    font-size: 10px;
    font-weight: $fontBold;
    color: $attention;
    text-transform: uppercase;
    margin-bottom: 1px;
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 7px;
    overflow: hidden;

    .date {
      font-weight: $fontBold;
      font-size: 12px;
      @include lo-advance-elipsis(1);
    }

    .right {
      display: flex;

      .label {
        @include lo-ellipsis;
        font-weight: $fontMedium;
        color: #ffffff;
        font-size: 10px;

        .tab {
          padding: 2px 3px;
          border-radius: 3px;
        }
      }

      .birthDay {
        position: relative;
        margin-left: 3px;
        top: -2px;
      }
    }
  }

  .info {
    > div {
      @include lo-advance-elipsis(2);
      overflow: hidden;

      &.accent {
        font-weight: $fontMedium;
      }

      &:not(:last-child) {
        margin-bottom: 2px;
      }
    }
  }
}
