@import 'ui/Colors';

.wrapper {
  flex: 1;
  min-height: 450px;

  .toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    height: 40px;
    border-bottom: solid 1px $border;
    font-size: 15px;

    .group {
      display: flex;
      align-items: center;
    }

    .group > :not(:last-child) {
      margin-right: 20px;
    }

    .backLink {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: $link;

      span {
        margin-left: 6px;
      }
    }
  }
}

.serviceHead {
  padding-top: 11px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 28px;

  .name {
    font-size: 23px;
    font-weight: $fontMedium;
    margin-bottom: 4px;
  }

  .meta {
    display: flex;
    align-items: center;
    font-size: 17px;
    margin-bottom: 1px;

    .payed {
      display: flex;
      align-items: center;
      margin-right: 25px;
    }
  }
}
