@import 'ui/Colors';

.wrapper {
  position: fixed;
  margin: auto;
  z-index: 101;
  width: 100%;
  height: 100%;
  padding-top: 35px;
  padding-bottom: 30px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  animation: slideUp 0.25s forwards;

  &.padding {
    padding-left: 20px;
    padding-right: 20px;
  }

  &.closing {
    animation: slideDown 0.25s forwards;
  }
}

@keyframes slideUp {
  0% {
    opacity: 0.8;
    bottom: -100px;
  }

  100% {
    opacity: 1;
    bottom: 0;
  }
}

@keyframes slideDown {
  0% {
    opacity: 0.8;
    bottom: 0;
  }

  100% {
    opacity: 0;
    bottom: -100px;
  }
}

@media (min-width: $mobileLimit) {
  .wrapper {
    width: 375px;
    height: 350px;
    border: solid 1px $border;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
  }
}
