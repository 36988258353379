@import 'ui/Colors';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 17px;
}

.name {
  flex: 1;
  @include lo-ellipsis;
  margin-right: 20px;
}

.right {
  display: flex;
  align-items: center;

  .block {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &:not(:last-child) {
      margin-right: 40px;
    }
  }

  .label {
    margin-top: 1px;
    padding: 2px 3px;
    border-radius: 3px;
    font-weight: $fontMedium;
    color: #ffffff;
    font-size: 9px;
  }
}
