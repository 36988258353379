@import 'ui/Colors';

.withLabel {
  user-select: none;
  display: flex;
  align-items: center;
  cursor: pointer;

  .label {
    margin-left: 10px;
    padding-top: 2px;
    font-size: 15px;
  }
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border: solid 1px $border;
  border-radius: 3px;

  .inner {
    display: none;
    opacity: 0;
    width: 16px;
    height: 16px;
    background: #ffffff;
  }

  &.checked {
    border: solid 1px darken($primary, 5%);
    animation: makeShadow 0.1s forwards;

    .inner {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      background: $primary;
      animation: fadeIn 0.1s forwards;
    }
  }
}

@keyframes makeShadow {
  0% {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0);
  }

  100% {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    width: 1px;
    height: 1px;
  }

  100% {
    width: 16px;
    height: 16px;
    opacity: 1;
  }
}
