@import 'ui/Colors';

.wrapper {
  user-select: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

.header {
  background: $secondary;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding-right: 5px;

  &.withoutBack {
    padding-left: 20px;
  }

  &.rightPadding {
    padding-right: 20px;
  }
}

.search {
  background: $secondary;
  height: 60px;
  padding: 5px 20px;

  .searchIcon {
    padding-left: 6px;
  }
}

.left {
  min-width: 0;
  display: flex;
  align-items: center;

  .title {
    flex: 1;
    color: #ffffff;
    font-size: 19px;
    font-weight: $fontMedium;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.right {
  align-items: center;
  justify-content: flex-end;
  display: flex;
  margin-left: 12px;
}

.placeholder {
  height: 60px;

  &.withSearch {
    height: 120px;
  }
}

@media print {
  .wrapper,
  .placeholder {
    display: none;
  }
}
