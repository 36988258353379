@import 'ui/Colors';

.wrapper {
  display: flex;
  padding-top: 16px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 15px;

  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: $secondary;
    color: #ffffff;
    font-weight: $fontMedium;
    font-size: 17px;
  }

  .greet {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .name {
      font-size: 22px;
      font-weight: $fontMedium;
    }

    .message {
      font-size: 17px;
    }
  }
}

@media (min-width: $mobileLimit) {
  .wrapper {
    padding: 0;
  }
}
