@import 'ui/Colors';

.location {
  display: flex;
  align-items: center;
  font-size: 17px;

  .circle {
    min-width: 12px;
    max-width: 12px;
    height: 12px;
    background: $primary;
    border-radius: 50%;
    margin-right: 13px;
  }
}
