@import 'ui/Colors';

.withLabel {
  user-select: none;
  display: flex;
  align-items: center;
  font-size: 17px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 12px;
  background: #ffffff;
  border: solid 1px $primary;

  .inner {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #ffffff;

    &.checked {
      background: $primary;
    }
  }
}

@media (min-width: $mobileLimit) {
  .wrapper {
    margin-right: 18px;
  }
}
